import { SectionComponents } from '~/types/components'

export default function () {
  const resolveSectionComponent = (block: SectionComponents) => {
    if (!block) {
      return
    }

    const componentName = Object.entries(SectionComponents).find(([key, _]) => key === block)?.[1]

    if (!componentName) {
      // eslint-disable-next-line no-console
      console.error(`Component for model type [${block}] does not exist.`)
      return
    }
    return resolveComponent(componentName)
  }

  return {
    resolveSectionComponent
  }
}
