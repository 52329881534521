// https://github.com/nuxt/framework/issues/6711

export enum SectionComponents {
  // Used on Index Model / Hero field
  SectionHeroRecord = 'SectionHero',
  SectionTitleRecord = 'SectionTitle',

  // Used on Index Model / Blocks field
  SectionArticleListRecord = 'SectionArticles',
  SectionRelatedArticlesListRecord = 'SectionRelatedArticles',
  SectionBodyRecord = 'SectionBody',
  SectionContactFormRecord = 'SectionContactForm',
  SectionCtaRecord = 'SectionCta',
  SectionFaqRecord = 'SectionFaq',
  SectionFeaturesListRecord = 'SectionFeatures',
  SectionImageGalleryRecord = 'SectionImageGallery',
  SectionInquiryFormRecord = 'SectionInquiryForm',
  SectionLogosListRecord = 'SectionLogos',
  SectionMediaBlockRecord = 'SectionMedia',
  SectionProjectsListRecord = 'SectionProjects',
  SectionTestimonialsListRecord = 'SectionTestimonials',
  SectionVideoRecord = 'SectionVideo'
}

export enum MenuBlocks {
  MenuCtaRecord = 'MenuCallToAction',
  MenuGroupRecord = 'MenuGroup'
}
